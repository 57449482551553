import { Dialog, DialogPanel } from "@headlessui/react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({ variant, addVariantToState, handleCancelButton }) => {
    const modal = useModal();
    async function handleSubmit(evt) {
      evt.preventDefault();
      let data = Object.fromEntries(new FormData(evt.target));
      data.leadtime *= 60;
      let targetURL = evt.target.action;
      if (variant) {
        targetURL += `/${variant.ressource_id}`;
      }
      let resp = await fetch(targetURL, {
        method: variant ? "PUT" : "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const newVariant = await resp.json();
      addVariantToState(newVariant);
      modal.hide();
    }
    return (
      <Dialog
        open={modal.visible}
        onClose={() => {
          modal.hide();
        }}
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel>
              <div
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6"
                id="adduse-modal-panel"
              >
                <div>
                  <form
                    className="mt-8 space-y-6"
                    action="/api/admin/variants"
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <div className="-space-y-px rounded-md shadow-sm">
                      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="col-span-6">
                          <label htmlFor="name">Bezeichnung*</label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            required
                            defaultValue={variant?.name}
                            className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6">
                          <label htmlFor="short_description">
                            Beschreibung
                          </label>
                          <input
                            id="short_description"
                            name="short_description"
                            type="text"
                            defaultValue={variant?.short_description}
                            className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                          />
                          <p className="mt-1 text-sm text-gray-600">
                            Diese Beschreibung wird unter der Bezeichung in der
                            Variantenauswahl angezeigt.
                          </p>
                        </div>
                        <div className="col-span-6">
                          <label htmlFor="ipLeadtime">Vorlaufzeit</label>
                          <input
                            id="ipLeadtime"
                            name="leadtime"
                            type="number"
                            min={0}
                            defaultValue={variant?.leadtime / 60 || 0}
                            className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                          />
                          <p className="mt-1 text-sm text-gray-600">
                            Wieviele Minuten vor Beginn des Termins die Buchung
                            erfolgen muss.
                          </p>
                        </div>
                        <div className="col-span-6">
                          <label htmlFor="name">Adresse</label>
                          <input
                            id="location"
                            name="location"
                            type="text"
                            placeholder="z.B. Musterstraße 123, 12345 Musterstadt"
                            defaultValue={variant?.location}
                            className="relative block w-full appearance-none rounded--md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-stone-500 focus:outline-none focus:ring-stone-500 sm:text-sm"
                          />
                          <p className="mt-1 text-sm text-gray-600">
                            Wenn der Standort für diese Variante abweicht, kann
                            hier eine Adresse angegeben werden, die die Daten
                            aus den Einstellungen überschreibt."
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-1">
                      <button
                        type="submit"
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                      >
                        Speichern
                      </button>
                      <button
                        type="button"
                        onClick={() => modal.hide()}
                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2"
                      >
                        Beenden
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    );
  }
);
